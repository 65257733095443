<template lang="pug">
  .insights__detail-drawer
    .insights__detail-drawer__header
      h2.title {{ title }}
      p.subtitle {{ subtitle }}
    .insights__detail-drawer__body
      .insights__detail-drawer__badge-data__tabs
        .insights__detail-drawer__badge-data__tabs--item(
          v-for="item in tabs"
          :key="item.tabId"
          :class="{ 'insights__detail-drawer__badge-data__tabs--active': item.tabId === currentTab }"
          @click="setCurrentTab(item.tabId)")
          | {{ item.name }}
      details-pie-chart(
        v-show="currentTab === 1"
        isBadgePie
        :pieChartData="pieChartData")
      badge-data-description(
        v-show="currentTab === 2"
        :activeBadge="activeBadge")
      badge-data-users(
        v-show="currentTab === 3"
        :active-badge="activeBadge"
        :get-employees="getEmployees")

</template>

<script>
import DetailsPieChart from './DetailsPieChart'
import BadgeDataDescription from './BadgeDataDescription'
import BadgeDataUsers from './BadgeDataUsers'

export default {
  components: {
    DetailsPieChart,
    BadgeDataDescription,
    BadgeDataUsers
  },

  props: {
    title: String,
    subtitle: String,
    pieChartData: Array,
    activeBadge: Object,
    getEmployees: Function
  },

  data: () => ({
    loading: false
  }),

  methods: {
    setCurrentTab (tab) {
      this.activeBadge.currentTab = tab
    }
  },

  computed: {
    tabs () {
      if (this.activeBadge.mainBadge) {
        if (this.currentTab === 2) {
          this.setCurrentTab(1)
        }
        return [
          { name: this.$t('ui.labels.insights'), tabId: 1 },
          { name: this.$t('ui.labels.users'), tabId: 3 }
        ]
      }
      return [
        { name: this.$t('ui.labels.insights'), tabId: 1 },
        { name: this.$t('ui.labels.description'), tabId: 2 },
        { name: this.$t('ui.labels.users'), tabId: 3 }]
    },

    currentTab () {
      return this.activeBadge.currentTab || 1
    }
  }
}
</script>

<style lang="scss" scoped>

  .insights__detail-drawer__badge-data {

    &__tabs {
      display: flex;
      width: 70%;
      padding-bottom: 1rem;

      &--item {
        &:nth-child(2){
          margin-left: 42px;
        }

        &:nth-child(3){
          margin-left:42px;
        }

        position: relative;
        font-weight: 600;
        font-size: 1.4rem;
        cursor: pointer;
        padding-bottom: 1rem;
      }

      &--active {
        color: $color-primary;

        &:after {
          content: "";
          width: 14px;
          height: 2px;
          border-radius: 6px;
          margin: 4px auto 0;
          background-color: $color-primary;
          position: absolute;
          left: calc(50% - 6px);
          bottom: 5px;
        }
      }
    }
  }
</style>
