<template lang="pug">
  v-scrollable(
    updatable
    :bottom-offset="40")
    .insight-detail__pie-chart
      v-list-group(
        v-for="(item, index) in pieChartData"
        :key="item.label"
        hasTriangle
        openByDefault)
        template(slot="header")
          h4.insight-detail__pie-chart--title {{ item.label | translate}}
          v-info-icon.insight-detail__pie-chart--info(
            v-if="isBadgePie"
            :text="$t(item.tooltip)")
        .insight-detail__pie-chart--container(slot="body")
          .insight-detail__pie-chart--container--item
            v-pie-chart(
              :chartdata="item.chartData"
              :options="options")
</template>

<script>
export default {
  name: 'DetailsPieChart',

  props: {
    pieChartData: Array,
    isBadgePie: Boolean
  },

  computed: {
    options () {
      return {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              if (data.empty) {
                return this.$t(`pages.insights.all_${data.dataKey}`) + ': 0 ' + this.$t('ui.labels._employees')
              }
              let tooltip = data.labels[tooltipItem.index] + ': ' +
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' ' + this.$t('ui.labels.users')
              return tooltip
            }
          }
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>

  .insight-detail {
    &__pie-chart {
      transform: translate(-10px);

      &--title {
        font-weight: 600;
        color: $h2-title
      }

      &--container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 2rem 9px 2rem;

        &--item {
          width:250px;
          height:250px;
        }
      }

      &--info {
        margin-left: 10px
      }
    }
  }
</style>
